<template>
    <v-model-table :data-function="getReviewReport" :columns="columns" :show-toolbar="false">
        <template v-slot:column_order_id="{ row }">
            <v-link :to="{ name: 'orders.show', params: { order: row.order_id } }">
                {{ (row.order_id) }}
            </v-link>
        </template>

        <template v-slot:column_user_name="{ row }">
            <v-link :to="{ name: 'users.show', params: { user: row.user_id } }">
                {{ (row.user_name) }}
            </v-link>
        </template>
    </v-model-table>
</template>

<script>
import ReportService from "@/services/modules/report-service";

export default {
    data() {
        return {
            getReviewReport: (payload = {}) => ReportService.getReviewReport(payload),
            columns: {
                order_id: {
                    label: "Order ID",
                    fullWidth: false,
                },
                created_at: {
                    label: "Order Completed At",
                    fullWidth: false,
                },
                coupon_code: {
                    label: "Coupon Code",
                    fullWidth: false,
                },
                coupon_discount: {
                    label: "Coupon Discount",
                    fullWidth: false,
                    align: "right",
                },
                status_name: {
                    label: "Review Status",
                    fullWidth: false,
                },
                click_count: {
                    label: "# Of Clicks",
                    fullWidth: false,
                    align: "center",
                },
            },
        };
    },
};
</script>
